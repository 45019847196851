#home-background {
	background-image: url("../../public/images/wheel_background.svg"), linear-gradient(106deg, rgb(159, 169, 255) -0.71%, rgb(45, 83, 157) 100%); /* Using this relative path is the only way to avoid placing the image in the src directory. */
	background-size: 70%, contain;
	background-position: top 0px right 0px, center center;
	background-repeat: no-repeat;
}

#home-outer-container {
	border-radius: 30px;
	background: linear-gradient(180deg, rgba(28, 52, 100, 0.90) 0%, rgba(52, 87, 157, 0.90) 52.04%, rgba(74, 92, 255, 0.90) 100%);
	box-shadow: 0px 0px 18px 0px rgba(28, 52, 100, 0.50);
}

.home-inner-container {
	background: linear-gradient(163deg, #FFF 0.48%, #C8DAFF 101%);
	border-radius: 15px;
	color: var(--dark-blue);
}

.home-inner-container form button {
	background-color: var(--light-blue);
	border-color: var(--light-blue);
}

.home-inner-container form button:hover {
	background-color: var(--light-blue);
	border-color: var(--light-blue);
}

/* xs: */
.home-inner-container-size {
	width: 90%;
}

.home-inner-container-title {
	font-size: 24px;
	font-weight: bold;
}

/* sm: */
@media (min-width: 576px) {
	.home-inner-container-size {
		width: 85% !important;
	}

	.home-inner-container-title {
		font-size: 26px;
		font-weight: bold;
	}
}

/* md: */
@media (min-width: 768px) {
	.home-inner-container-size {
		width: 80% !important;
	}

	.home-inner-container-title {
		font-size: 28px;
		font-weight: bold;
	}
}

/* lg: */
@media (min-width: 992px) {
	.home-inner-container-size {
		width: 60% !important;
	}

	.home-inner-container-title {
		font-size: 30px;
		font-weight: bold;
	}
}

/* xl: */
@media (min-width: 1200px) {
	.home-inner-container-size {
		width: 55% !important;
	}

	.home-inner-container-title {
		font-size: 32px;
		font-weight: bold;
	}
}

/* xxl: */
@media (min-width: 1400px) {
	.home-inner-container-size {
		width: 50% !important;
	}

	.home-inner-container-title {
		font-size: 34px;
		font-weight: bold;
	}
}

/* xxxl: */
@media (min-width: 1600px) {
	.home-inner-container-size {
		width: 35% !important;
	}

	.home-inner-container-title {
		font-size: 36px;
		font-weight: bold;
	}
}

#required-fields-text {
	font-size: 14px;
}
