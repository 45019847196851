#home-footer {
	color: white;
	font-size: 14px;
	text-align: center;
}

#home-footer a {
	color: white;
}

#home-footer img {
	max-width: 100%;
}

#trustpilot-icon {
	transform: translateY(-15%);
}
