#details-container {
	background-color: white;
	border-left: 7px solid #FF814A;
	border-top-left-radius: 12px;
	border-bottom-left-radius: 12px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 6, 0.15);
	max-width: none;
}

#details-container > .row:nth-of-type(1) {
	border-bottom: 2px solid var(--dark-blue);
}

#details-container .row:nth-of-type(1) > .col {
	color: var(--dark-blue);
	font-size: 20px;
	font-weight: bold;
}

#details-container .row:nth-of-type(2) > .col:nth-of-type(1) {
	color: var(--dark-blue);
	font-size: 18px;
	line-height: 175%;
}

#details-container table {
	border: none;
	border-collapse: collapse;
	border-radius: 5px;
	overflow: hidden; /* Necessary to round the borders. */
}

#details-container table th {
	background-color: var(--dark-blue);
	border: none;
	color: white;
}

#details-container table tr {
	vertical-align: middle;
}

#details-container table td {
	border: none;
	color: var(--really-dark-grey);
	font-weight: 500;
}

#details-container table > tbody > tr:nth-child(odd) > td {
	background-color: var(--light-grey);
}

#details-container table > tbody > tr:nth-child(even) > td {
	background-color: var(--very-light-grey);
}
