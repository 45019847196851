#settings-container {
	background-color: white;
	border-left: 7px solid var(--dark-blue);
	border-top-left-radius: 12px;
	border-bottom-left-radius: 12px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 6, 0.15);
	max-width: none;
	padding-top: 2%;
	padding-bottom: 2%;
	padding-left: 15%;
	padding-right: 15%;
}

#settings-container #user-name-title {
	color: var(--dark-blue);
	font-size: 32px;
	font-weight: bold;
	line-height: 155%;
}

#settings-container #image-circle {
	aspect-ratio: 1/1;
	background-color: var(--very-light-grey);
	border: 3px solid var(--dark-blue);
	border-radius: 50%;
	position: relative;
}

#settings-container #image-circle img {
	aspect-ratio: 1/1;
	border-radius: 50%;
	position: absolute;
	top: 0px;
	transform: translate(-50%, 0%);
	width: 100%;
}

#settings-container .image-button {
	background-color: white;
	border: 1px solid var(--dark-blue);
	color: var(--dark-blue);
	width: 90px;
	font-size: 14px;
	font-weight: bold;
	line-height: 155%;
}

#settings-container #password-field {
	position: relative;
}

#settings-container #password-field img {
	position: absolute;
	right: 15px;
	top: 10px;
	z-index: 5;
}

#settings-container #save-button {
	width: 100% !important;
}

@media only screen and (min-width: 768px) {
	#settings-container #save-button {
		width: auto !important;
	}
}
