#confirmation-box-modal {
	position: fixed;
	left: 0%; /* Horizontal alignment in the viewport */
	right: 0%; /* Horizontal alignment in the viewport */
	top: 50%; /* Vertical alignment in the viewport */
	transform: translateY(-50%); /* Vertical alignment in the viewport */
}

#confirmation-box-modal .modal-body > .row:nth-of-type(1) > .col {
	color: var(--very-dark-grey);
	font-size: 14px;
	font-weight: 500;
}
