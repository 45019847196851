#menu-logo {
	height: 40px;
}

.menu-item, .menu-item:hover, .menu-item:visited {
	color: white !important;
}

.menu-item-text {
	font-size: 16px;
}

/* sm: */
@media (min-width: 576px) {
	#menu-logo {
		height: 42px;
	}

	.menu-item-text {
		font-size: 16px;
	}
}

/* md: */
@media (min-width: 768px) {
	#menu-logo {
		height: 46px;
	}

	.menu-item-text {
		font-size: 16px;
	}
}

/* lg: */
@media (min-width: 992px) {
	#menu-logo {
		height: 48px;
	}

	.menu-item-text {
		font-size: 16px;
	}
}

/* xl: */
@media (min-width: 1200px) {
	#menu-logo {
		height: 50px;
	}

	.menu-item-text {
		font-size: 17px;
	}
}

/* xxl: */
@media (min-width: 1400px) {
	#menu-logo {
		height: 52px;
	}

	.menu-item-text {
		font-size: 18px;
	}
}

/* xxxl: */
@media (min-width: 1600px) {
	#menu-logo {
		height: 56px;
	}

	.menu-item-text {
		font-size: 20px;
	}
}

#signup-link {
	background-color: white;
	border-color: white;
	border-radius: 30px;
	color: var(--dark-blue) !important;
	font-weight: bold;
	height: fit-content;
	text-decoration: none;
	width: fit-content;
}
