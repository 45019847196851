#dashboard-container {
	max-width: none;
	overflow-y: auto;
	padding: 0;
}

#dashboard-container > .row:nth-of-type(2) > .col {
	min-width: 300px;
	max-width: 300px;
}

#dashboard-alert {
	min-width: 300px;
	max-width: fit-content;
}

#search-term {
	width: 295px;
}
