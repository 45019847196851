#signup-footer span {
	font-weight: 500;
}

#signup-footer a {
	color: var(--dark-blue);
	font-size: 18px;
	font-weight: 800;
}

#signup-modal {
	position: fixed;
	left: 0%; /* Horizontal alignment in the viewport */
	right: 0%; /* Horizontal alignment in the viewport */
	top: 50%; /* Vertical alignment in the viewport */
	transform: translateY(-50%); /* Vertical alignment in the viewport */
}

#signup-modal .modal-content {
	background: linear-gradient(163deg, #FFF 0.48%, #C8DAFF 101%);
	border-radius: 15px;
	color: var(--dark-blue);
	text-align: center;
	padding-bottom: 2%;
	padding-top: 2%;
}

#signup-modal h1 {
	font-size: 36px;
	font-weight: bold;
}

#signup-modal p {
	font-size: 17px;
	font-weight: 500;
}

#signup-modal button {
	background: var(--light-blue);
	border-color: var(--light-blue);
	color: #FFF;
	font-weight: bold;
	padding: 7px 50px;
}
