@import url("https://fonts.cdnfonts.com/css/maven-pro-2");

:root {
	--dark-blue: #1C3464;
	--light-blue: #5E93FF;
	--very-light-grey: #f5f5f5;
	--light-grey: #1C34641A;
	--average-grey: #8383834D;
	--dark-grey: #46445680;
	--very-dark-grey: #838383;
	--really-dark-grey: #515151;
	--reddish: #FF7070;
	--bright-green: #5CCC5A;
	--violet: #8990CA;
	--less-opaque-white: #FFFFFF20;
	--more-opaque-white: #FFFFFF10;
	--blue-gradient-left-to-right: linear-gradient(to right, #34569CFF, #34569C3C)
}

::placeholder {
	color: var(--dark-grey) !important;
	opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12-18 */
	color: var(--dark-grey) !important;
}

body {
	font-family: "Maven Pro", sans-serif;
}

input[type="checkbox"], input[type="radio"] {
	border-color: var(--very-dark-grey);
}

input[type="checkbox"]:checked {
	background-color: var(--dark-blue);
}

input[type="radio"]:checked {
	background-color: white;
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%231C3464%27/%3e%3c/svg%3e");
	border-color: var(--very-dark-grey);
}

label.disabled {
	color: var(--dark-grey);
}

.select__value-container {
	width: 100px; /* This solves the overflow issue for react-select. */
}

.border-left-0 {
	border-left-color: #FF814A !important;
}

.border-left-1 {
	border-left-color: #0BB885 !important;
}

.border-left-2 {
	border-left-color: #EC2F77 !important;
}

.border-left-3 {
	border-left-color: #4A5CFF !important;
}

.border-left-4 {
	border-left-color: #FF4A4A !important;
}

.border-left-5 {
	border-left-color: #5CCC5A !important;
}

.border-left-6 {
	border-left-color: #00CFE8 !important;
}

.border-left-7 {
	border-left-color: #E6FF4A !important;
}

.border-left-8 {
	border-left-color: #027C00 !important;
}

.border-left-9 {
	border-left-color: #BB2FEC !important;
}

button {
	caret-color: transparent;
	font-weight: bold !important;
}

img {
	caret-color: transparent;
}

input[type="radio"], .transparent-cursor {
	caret-color: transparent;
}

.cursor-pointer {
	cursor: pointer;
}

.home-button {
	background-color: white !important;
	border-color: white !important;
	border-radius: 12px !important;
	color: var(--dark-blue) !important;
	display: inline-block;
	font-weight: bold;
	height: 44px;
	padding: 0.5rem 1rem !important;
	text-decoration: none;
}

.standard-label {
	font-size: 14px;
	margin-bottom: 0.2rem;
}

.error-border, .error-border .select__control {
	border-color: tomato !important;
}

.error-label {
	color: tomato;
	float: left;
	font-size: 12px;
}

.transparent-button, .transparent-button:active, .transparent-button:focus, .transparent-button:hover {
	background-color: transparent !important;
	border-color: transparent !important;
}

.standard-light-button, .standard-light-button:active, .standard-light-button:focus, .standard-light-button:hover {
	background-color: white !important;
	border-color: var(--very-dark-grey) !important;
	color: var(--very-dark-grey) !important;
	height: 2.75rem;
	padding: 0.5rem 1rem;
}

.standard-dark-button, .standard-dark-button:active, .standard-dark-button:focus, .standard-dark-button:hover {
	background-color: var(--dark-blue) !important;
	border-color: var(--dark-blue) !important;
	color: white !important;
	height: 2.75rem;
	padding: 0.5rem 1rem;
}

.edit-button, .edit-button:active, .edit-button:focus, .edit-button:hover {
	background-color: var(--bright-green) !important;
	border-color: var(--bright-green) !important;
	color: white !important;
	padding: 0.33rem 0.75rem;
}

.delete-button, .delete-button:active, .delete-button:focus, .delete-button:hover {
	background-color: var(--reddish) !important;
	border-color: var(--reddish) !important;
	color: white !important;
	padding: 0.33rem 0.75rem;
}

.enable-button, .enable-button:active, .enable-button:focus, .enable-button:hover {
	background-color: var(--violet) !important;
	border-color: var(--violet) !important;
	color: white !important;
	padding: 0.33rem 0.75rem;
}

.disable-button, .disable-button:active, .disable-button:focus, .disable-button:hover {
	background-color: var(--really-dark-grey) !important;
	border-color: var(--really-dark-grey) !important;
	color: white !important;
	padding: 0.33rem 0.75rem;
}

.opacity-40 {
	opacity: 0.4;
}

.password-field {
	position: relative;
}

.password-field img {
	position: absolute;
	right: 0px;
	top: 0px;
	transform: translate(-50%, 50%);
	z-index: 5;
}

.password-field > #password-tooltip {
	background-color: var(--dark-blue);
	border-radius: 5px;
	color: white;
	font-size: 12px;
	padding: 3px 6px;
	position: absolute;
	top: 150%;
	z-index: 1;
}

.password-field > #password-tooltip > ul {
	padding-left: 1.5rem;
}

.password-field #password-tooltip::after {
	border-color: transparent transparent var(--dark-blue) transparent;
	border-style: solid;
	border-width: 5px;
	content: "";
	left: 50%;
	margin-top: -9px;
	position: absolute;
	top: 0%;
}

.modal-title {
	color: var(--dark-blue);
	font-size: 20px;
	font-weight: bold;
}

.modal-body-min-height {
	min-height: 300px;
}

.inline-grid {
	display: inline-grid;
}

.truncated-text {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.break-line {
	white-space: pre-line;
}

.break-all {
	word-break: break-all;
}

.pagination > li > a {
	color: var(--dark-blue);
}

.pagination > .active > .page-link {
	background-color: var(--dark-blue);
	border-color: var(--dark-blue);
}

#info-button {
	background-color: var(--dark-blue);
	border-color: var(--dark-blue);
	border-radius: 8px;
	color: white;
	font-family: monospace;
	font-size: 10px;
	height: 16px;
	width: 16px;
	text-align: center;
}

#credentials-tooltip {
	background-color: var(--dark-blue);
	border-radius: 5px;
	color: white;
	font-size: 12px;
	padding: 3px 6px;
	position: absolute;
	top: 22px;
	right: 11px;
	width: 200px;
	z-index: 1;
}

#credentials-tooltip::after {
	border-color: transparent transparent var(--dark-blue) transparent;
	border-style: solid;
	border-width: 5px;
	content: "";
	right: 4px;
	margin-top: -9px;
	position: absolute;
	top: 0;
}

.spinner {
	color: var(--dark-blue);
	left: calc(50% - 1rem);
	position: absolute;
	top: calc(50% - 1rem);
}

.w-33 {
	width: 33% !important;
}

/* xs: */
#search-box {
	width: 150px;
}

.col-5_7 {
	flex: 0 0 auto;
	width: 47.5%
}

.uloi-title {
	font-size: 48px;
	font-weight: bold;
	line-height: 1
}

/* sm: */
@media (min-width: 576px) {
	#search-box {
		width: 250px;
	}

	.col-sm-5_7 {
		flex: 0 0 auto;
		width: 47.5%
	}

	.uloi-title {
		font-size: 52px;
	}

	.w-sm-50 {
		width: 50% !important;
	}
}

/* md: */
@media (min-width: 768px) {
	.col-md-5_7 {
		flex: 0 0 auto;
		width: 47.5%
	}

	.uloi-title {
		font-size: 56px;
	}
}

/* lg: */
@media (min-width: 992px) {
	.col-lg-5_7 {
		flex: 0 0 auto;
		width: 47.5%
	}

	.uloi-title {
		font-size: 56px;
	}
}

/* xl: */
@media (min-width: 1200px) {
	.col-xl-5_7 {
		flex: 0 0 auto;
		width: 47.5%
	}

	.uloi-title {
		font-size: 64px;
	}
}

/* xxl: */
@media (min-width: 1400px) {
	.col-xxl-5_7 {
		flex: 0 0 auto;
		width: 47.5%
	}

	.uloi-title {
		font-size: 68px;
	}
}

/* xxxl: */
@media (min-width: 1600px) {
	.col-xxxl-5_7 {
		flex: 0 0 auto;
		width: 47.5%
	}

	.uloi-title {
		font-size: 84px;
	}
}
