#account-container {
	min-height: 100vh;
}

#account-container .row {
	margin: 0;
}

#account-container .col {
	padding: 0;
}

#sidebar-menu {
	background-color: white;
	position: fixed;
	z-index: 1;
}

#sidebar-menu ul {
	list-style-type: none;
	display: inline-flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 0;
	width: 100%;
}

.sidebar-menu-small-screen {
	max-width: 100px !important;
	min-width: 100px;
}

.sidebar-menu-large-screen {
	max-width: 250px !important;
	min-width: 250px;
}

#x-icon {
	color: var(--very-dark-grey);
}

.right-panel-expanded {
	margin-left: 250px;
}

.right-panel-collapsed {
	margin-left: 100px;
}

.right-panel-compact {
	margin-left: 0px;
}

.menu-item {
	border-radius: 0.75rem;
	cursor: pointer;
	display: block;
	padding: 1rem 1.25rem;
	text-decoration: none;
}

.menu-item > span {
	color: var(--very-dark-grey);
}

.menu-item-active {
	background-color: var(--dark-blue);
}

.menu-item-active > span {
	color: white;
}

#hamburger-icon {
	background-color: white;
	border: 1px solid var(--dark-blue);
	border-radius: 5px;
	color: var(--dark-blue);
	display: flex;
	height: fit-content;
	width: fit-content;
	padding: 2px 10px;
}

#welcome-bar {
	border-bottom: 2px solid var(--dark-blue);
	padding: 1.5rem 1.25rem;
	height: 80px;
}

#welcome-bar > .col:nth-of-type(1) {
	color: var(--dark-blue);
	font-size: 30px;
	font-weight: 500;
}

#welcome-bar #image-circle {
	background-color: var(--very-light-grey);
	border: 1px solid var(--dark-blue);
	border-radius: 50%;
	height: 34px;
	min-width: 34px;
	max-width: 34px;
}

#welcome-bar #image-circle > img {
	aspect-ratio: 1/1;
	border-radius: 50%;
	width: 100%;
}

#dropdown-user-name {
	color: var(--dark-blue);
	font-weight: bold;
	line-height: 20px;
	padding-left: 0;
	padding-right: 0;
}

#dropdown-user-name.dropdown-toggle::after {
	display: none;
}

#dropdown-user-name ~ .dropdown-menu {
	inset: 3px -1px auto auto !important;
	min-width: unset;
}

#dropdown-user-name ~ .dropdown-menu > .dropdown-item {
	color: var(--very-dark-grey);
	font-size: 14px;
	font-weight: 500;
}

#dropdown-user-name ~ .dropdown-menu > .dropdown-item:focus {
	background-color: var(--bs-dropdown-link-hover-bg);
}

#dropdown-notifications {
	padding: 0;
}

#dropdown-notifications::after {
	display: none;
}

#dropdown-notifications ~ .dropdown-menu {
	padding-top: 0;
	inset: 71px 0px auto -200px !important;
	transform: unset !important;
}

#dropdown-notifications ~ .dropdown-menu > .dropdown-header {
	background-color: white;
	border-bottom: 1px solid var(--average-grey);
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	color: var(--dark-blue);
	font-size: 20px;
	font-weight: bold;
}

#dropdown-notifications ~ .dropdown-menu > .dropdown-item {
	color: var(--very-dark-grey);
	font-size: 14px;
	font-weight: 500;
}

#dropdown-notifications ~ .dropdown-menu > .dropdown-item > div:nth-of-type(1) {
	width: 80%;
	white-space: normal;
	display: inline-block;
}

#account-page {
	background-color: var(--very-light-grey);
	flex: 1;
}

#account-page > .col {
	position: relative;
}

#navigation-bar {
	border-bottom: 1px solid var(--average-grey);
}

#navigation-bar > .col {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

#navigation-bar .row:nth-of-type(1) {
	color: var(--dark-blue);
	font-size: 30px;
	font-weight: bold;
}

#navigation-bar .row:nth-of-type(2) {
	color: var(--very-dark-grey);
	font-size: 15px;
	font-weight: 500;
}

#navigation-bar .row:nth-of-type(2) a {
	color: var(--very-dark-grey);
	text-decoration: none;
}

#navigation-bar .row:nth-of-type(2) a:hover {
	text-decoration: underline;
}

#navigation-bar #current-page {
	color: var(--dark-blue);
}

#navigation-bar button {
	background-color: var(--dark-blue);
	border-color: var(--dark-blue);
	font-weight: bold;
	width: fit-content;
}

#breadcrums button > span {
	vertical-align: middle;
}

#page-footer {
	background-color: var(--very-light-grey);
	min-height: 40px;
}

#page-footer > .col {
	color: var(--very-dark-grey);
	font-size: 14px;
}

#page-footer a {
	color: var(--very-dark-grey);
	text-decoration: none;
}

.page-footer-border {
	border-top: 1px solid var(--average-grey);
}
