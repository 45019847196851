/* xs: */
#no-page-text1 {
	font-size: 22px;
}

#no-page-text2 {
	font-size: 16px;
}

/* sm: */
@media (min-width: 576px) {
	#no-page-text1 {
		font-size: 28px;
	}
	
	#no-page-text2 {
		font-size: 18px;
	}
}

/* md: */
@media (min-width: 768px) {
	#no-page-text1 {
		font-size: 32px;
	}
	
	#no-page-text2 {
		font-size: 20px;
	}
}

/* lg: */
@media (min-width: 992px) {
	#no-page-text1 {
		font-size: 36px;
	}
	
	#no-page-text2 {
		font-size: 22px;
	}
}

/* xl: */
@media (min-width: 1200px) {
	#no-page-text1 {
		font-size: 40px;
	}
	
	#no-page-text2 {
		font-size: 24px;
	}
}

/* xxl: */
@media (min-width: 1400px) {
	#no-page-text1 {
		font-size: 42px;
	}
	
	#no-page-text2 {
		font-size: 26px;
	}
}

/* xxxl: */
@media (min-width: 1600px) {
	#no-page-text1 {
		font-size: 48px;
	}
	
	#no-page-text2 {
		font-size: 30px;
	}
}
