.button-link {
	background-color: var(--less-opaque-white);
	border-radius: 20px;
	color: white;
	display: inline-block;
	font-size: 0.9rem;
	padding: 0.25rem 1rem;
	text-decoration: none;
}

#video-play-button {
	background-color: transparent;
	border-color: transparent;
	border-radius: 50%;
	left: 50%;
	padding: 0;
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	width: fit-content;
	z-index: 2;
}

#video-play-button img {
	width: 82px;
}

.help-box {
	border: 2px solid white;
	border-radius: 20px;
	padding: 0 !important;
}

#help-box-tech-support {
	background-image: var(--blue-gradient-left-to-right), url("../../public/images/tech-support.jpg");
	background-position: center;
	display: flex;
	align-items:center;
	min-height: 317px;
}

#download-icon {
	color: var(--dark-blue);
}

#faq-icon {
	background-color: white;
	border-radius: 50px;
	color: var(--dark-blue);
	font-weight: bold;
	padding: 0.3rem 0.6rem;
}

.accordion-item {
	background-color: var(--more-opaque-white) !important;
	border-color: var(--more-opaque-white) !important;
	border-radius: 10px !important;
}

.accordion-item:has(button.collapsed):hover {
	background-color: var(--less-opaque-white) !important;
}

.accordion-button {
	background-color: var(--more-opaque-white) !important;
	border-radius: 10px 10px 0 0 !important;
	box-shadow: none !important;
	color: white !important;
	justify-content: space-between;
}

.accordion-button::after { /* Native button */
	display: none;
}

.accordion-chevron {
	background-color: var(--less-opaque-white);
	border-radius: 50%;
	color: white;
	justify-content: end;
	padding: 0.5rem !important;
}

.accordion-button.collapsed {
	border-radius: 10px !important;
}

.accordion-button.collapsed .accordion-chevron {
	transition: transform 0.3s ease-in;
}

.accordion-button:not(.collapsed) .accordion-chevron {
	transform: rotate(180deg);
	transition: transform 0.3s ease-in;
}

.accordion-body {
	background-color: var(--more-opaque-white) !important;
	border-radius: 0 0 10px 10px !important;
	color: white !important;
	font-size: 0.9rem;
	padding-top: 0 !important;
}

.accordion-separator {
	background-color: var(--more-opaque-white);
	height: 1px;
	margin-bottom: var(--bs-accordion-btn-padding-y);
}

.faq-answer strong {
	font-weight: 800;
}

.faq-answer a {
	color: white;
}

/* xs: */
#help-inner-container {
	width: 100%;
}

#video-box {
	aspect-ratio: 16/9;
	border: 2px solid white;
	border-radius: 20px;
	height: 100%;
	width: 100%;
}

.help-section-title {
	font-size: 20px;
	font-weight: bold;
	line-height: 1
}

/* sm: */
@media (min-width: 576px) {
	#help-inner-container {
		width: 90%;
	}

	.help-section-title {
		font-size: 21px;
	}
}

/* md: */
@media (min-width: 768px) {
	#help-inner-container {
		width: 85%;
	}

	.help-section-title {
		font-size: 23px;
	}
}

/* lg: */
@media (min-width: 992px) {
	.help-section-title {
		font-size: 23px;
	}
}

/* xl: */
@media (min-width: 1200px) {
	#help-inner-container {
		width: 80%;
	}

	.help-section-title {
		font-size: 26px;
	}
}

/* xxl: */
@media (min-width: 1400px) {
	.help-section-title {
		font-size: 28px;
	}
}

/* xxxl: */
@media (min-width: 1600px) {
	.help-section-title {
		font-size: 35px;
	}
}
