#reset-password-message {
	font-size: 20px;
	font-weight: 500;
}

#reset-password-message.success-message {
	color: green;
}

#reset-password-message.error-message {
	color: red;
}
