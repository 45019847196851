#down-button {
	cursor: pointer;
	height: 80px
}

.feature-card {
	background-color: white;
	border-radius: 16px;
	box-shadow: 0px 4px 54px #0000000d;
	color: var(--dark-blue);
	position: relative;
}

.feature-icon {
	background-color: #dedcf1;
	border-radius: 40px;
	height: 80px;
	left: 50%;
	position: absolute;
	top: 0%;
	transform: translate(-50%, -50%);
	width: 80px;
}

.feature-icon img {
	position: absolute;
	left: 25%;
	top: 25%;
}

.feature-title {
	font-size: 26px;
	font-weight: 800;
}

.testimonial-card {
	background-color: white;
	border-radius: 16px;
	box-shadow: 0px 4px 54px #0000000d;
	color: black;
}

.testimonial-avatar {
	border-radius: 40px;
	height: 80px;
	width: 80px;
}

.testimonial-comment {
	color: #454456;
	opacity: 0.7;
}

.testimonial-full-name {
	color: var(--dark-blue);
	font-size: 18px;
	font-weight: 600;
}

.square-button, .square-button:active, .square-button:focus, .square-button:hover {
	background-color: var(--light-blue) !important;
	border-color: var(--light-blue) !important;
	border-radius: 0px !important;
	color: white !important;
	font-weight: bold;
	padding: 0.75rem 1.5rem !important;
}

#contact-form textarea {
	resize: none;
}

#contact-form a {
	color: white;
}

.contact-image {
	width: 50px;
	height: 50px;
	top: 0;
	left: 0;
}

/* xs: */
#rotating-wheel {
	width: 90%;
}

#chosen-by-customers {
	font-size: 18px;
}

.company-logo {
	width: 70%;
}

.feature-card, .testimonial-card {
	width: 100%;
}

#cubes {
	width: 100%;
}

.paragraph-title {
	font-size: 32px;
}

.paragraph-content {
	font-size: 16px;
}

#contacts-paragraph-title {
	font-size: 26px;
}

.contacts-block-title {
	font-size: 20px;
	font-weight: 500;
}

.contacts-block-info {
	font-size: 14px;
}

#up-button {
	bottom: 15px;
	cursor: pointer;
	height: 60px;
	position: fixed;
	right: 20px;
	z-index: 1;
}

/* sm: */
@media (min-width: 576px) {
	#rotating-wheel {
		width: 70%;
	}

	#chosen-by-customers {
		font-size: 19px;
	}

	.company-logo {
		width: 50%;
	}

	.feature-card, .testimonial-card {
		width: 60%;
	}

	#cubes {
		width: 100%;
	}

	.paragraph-title {
		font-size: 33px;
	}
	
	.paragraph-content {
		font-size: 16.5px;
	}

	#contacts-paragraph-title {
		font-size: 28px;
	}

	.contacts-block-title {
		font-size: 22px;
	}
	
	.contacts-block-info {
		font-size: 16px;
	}

	#up-button {
		bottom: 35px;
		right: 45px;
	}
}

/* md: */
@media (min-width: 768px) {
	#rotating-wheel {
		width: 50%;
	}

	#chosen-by-customers {
		font-size: 20px;
	}

	.company-logo {
		width: 100%;
	}

	.feature-card, .testimonial-card {
		width: 50%;
	}

	#cubes {
		width: 90%;
	}

	.paragraph-title {
		font-size: 34px;
	}
	
	.paragraph-content {
		font-size: 17px;
	}

	#contacts-paragraph-title {
		font-size: 30px;
	}

	.contacts-block-title {
		font-size: 24px;
	}
	
	.contacts-block-info {
		font-size: 16px;
	}

	#up-button {
		bottom: 40px;
		right: 50px;
	}
}

/* lg: */
@media (min-width: 992px) {
	#rotating-wheel {
		width: 100%;
	}

	#chosen-by-customers {
		font-size: 20px;
	}

	.company-logo {
		width: 100%;
	}

	.feature-card, .testimonial-card {
		width: 100%;
	}

	#cubes {
		width: 70%;
	}

	.paragraph-title {
		font-size: 34px;
	}
	
	.paragraph-content {
		font-size: 17px;
	}

	#contacts-paragraph-title {
		font-size: 30px;
	}

	.contacts-block-title {
		font-size: 25px;
	}
	
	.contacts-block-info {
		font-size: 16px;
	}

	#up-button {
		bottom: 40px;
		right: 50px;
	}
}

/* xl: */
@media (min-width: 1200px) {
	#rotating-wheel {
		width: 100%;
	}

	#chosen-by-customers {
		font-size: 21px;
	}

	.company-logo {
		width: 100%;
	}

	.feature-card, .testimonial-card {
		width: 100%;
	}

	#cubes {
		width: 100%;
	}

	.paragraph-title {
		font-size: 36px;
	}
	
	.paragraph-content {
		font-size: 18px;
	}

	#contacts-paragraph-title {
		font-size: 32px;
	}

	.contacts-block-title {
		font-size: 26px;
	}
	
	.contacts-block-info {
		font-size: 16px;
	}

	#up-button {
		bottom: 40px;
		right: 50px;
	}
}

/* xxl: */
@media (min-width: 1400px) {
	#rotating-wheel {
		width: 90%;
	}

	#chosen-by-customers {
		font-size: 20px;
	}

	.company-logo {
		width: 100%;
	}

	.feature-card, .testimonial-card {
		width: 80%;
	}

	#cubes {
		width: 100%;
	}

	.paragraph-title {
		font-size: 38px;
	}
	
	.paragraph-content {
		font-size: 19px;
	}

	#contacts-paragraph-title {
		font-size: 34px;
	}

	.contacts-block-title {
		font-size: 28px;
	}
	
	.contacts-block-info {
		font-size: 17px;
	}

	#up-button {
		bottom: 40px;
		right: 50px;
	}
}

/* xxxl: */
@media (min-width: 1600px) {
	#rotating-wheel {
		width: 80%;
	}

	#chosen-by-customers {
		font-size: 20px;
	}

	.company-logo {
		width: 100%;
	}

	.feature-card, .testimonial-card {
		width: 60%;
	}

	#cubes {
		width: 100%;
	}

	.paragraph-title {
		font-size: 42px;
	}

	.paragraph-content {
		font-size: 21px;
	}

	#contacts-paragraph-title {
		font-size: 36px;
	}

	.contacts-block-title {
		font-size: 30px;
	}
	
	.contacts-block-info {
		font-size: 18px;
	}

	#up-button {
		bottom: 40px;
		right: 50px;
	}
}
