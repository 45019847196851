.lms-card {
	background-color: white;
	border-left: 7px solid;
	border-top-left-radius: 12px;
	border-bottom-left-radius: 12px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 6, 0.15);
	color: var(--dark-blue);
	height: 220px;
	width: 300px;
	padding: 0.75rem 1.5rem;
	position: relative;
}

.lms-card > .row:nth-of-type(1) {
	border-bottom: 2px solid var(--dark-blue);
}

.lms-name {
	font-size: 18px;
	font-weight: bold;
	line-height: 175%;
}

.lms-body {
	font-size: 14px;
	line-height: 175%;
}

.lms-dropdown {
	position: absolute !important;
	top: 5px;
	right: 10px;
}

.lms-dropdown > .dropdown-toggle, .lms-dropdown > .dropdown-toggle:active, .lms-dropdown > .dropdown-toggle:focus {
	background-color: transparent !important;
	border-color: transparent !important;
	padding: 0;
}

.lms-dropdown > .dropdown-toggle::after {
	display: none;
}

.lms-dropdown > .dropdown-menu {
	inset: 30px 0px auto auto !important;
	min-width: unset;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 0;
	padding-right: 0;
	transform: unset !important;
}

.lms-dropdown > .dropdown-menu > .dropdown-item {
	color: var(--very-dark-grey);
	font-size: 14px;
	font-weight: 500;
}

.lms-dropdown > .dropdown-menu > .dropdown-item:focus {
	background-color: var(--bs-dropdown-link-hover-bg);
}

.show-users-button {
	position: absolute;
	bottom: 1rem;
	right: 0.75rem;
}
