#forgot-password {
	color: var(--dark-blue);
	font-size: 16px;
}

#login-footer span {
	font-weight: 500;
}

#login-footer a {
	color: var(--dark-blue);
	font-size: 18px;
	font-weight: 800;
}
